import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Image from "../../UI/Image/Image"
import Link from "../../UI/Link/Link"
import { get } from "../../../helpers/utils"
import { gaEventClasses, gaEventLabels } from "../../../constants/gtm.constants"
import * as styles from "./Header-tw-styles"
import { useGlobalContext } from "../../../context/GlobalContext"
import useScrollBlock from "../../../hooks/useScrollBlock"
import { v4 as uuidv4 } from "uuid"
import Hamburger from "./Hamburger"
import { languages } from "../../../constants/locale.constants"

const LanguageSwitcher = () => {
    const { language } = useGlobalContext()
    return (
        <div className="flex flex-nowrap items-center justify-center text-center gap-2">
            {languages.map(lang => {
                return (
                    <a
                        href={`/${lang}/`}
                        target="_self"
                        lang={lang}
                        rel="alternate"
                        key={uuidv4()}
                        className={`language-button ${lang === language ? "active" : ""}`}
                        aria-label={`Change language to ${lang}`}
                    >
                        {lang}
                    </a>
                )
            })}
        </div>
    )
}

const Header = props => {
    const style = styles.defaultStyle
    const { logo, navigationCollection } = props
    const labels = useGlobalContext().labels
    const [mobileNavOpen, setMobileNavOpen] = useState(false)
    const [blockScroll, allowScroll] = useScrollBlock()
    const navigationData = get(navigationCollection, "items", [])

    useEffect(() => {
        const closeMenu = () => {
            setMobileNavOpen(false)
        }
        window.addEventListener("resize", closeMenu)

        return () => {
            window.removeEventListener("resize", closeMenu)
        }
    }, [])

    useEffect(() => {
        if (mobileNavOpen) {
            blockScroll()
        } else {
            allowScroll()
        }
        return () => {
            allowScroll()
        }
    }, [mobileNavOpen])

    return (
        <header className={style.header}>
            <div className={style.wrapper}>
                <div className={style.logoWrapper}>
                    <Link
                        url={labels?.urls?.homepage}
                        title={labels?.header?.homepage}
                        onClick={() => {
                            if (mobileNavOpen) {
                                setMobileNavOpen(false)
                            }
                        }}
                    >
                        <Image
                            contentfulImage={logo}
                            className={style.logoImage + " " + gaEventClasses.image_click}
                            eventClass={gaEventClasses.logo}
                            eventLabel={gaEventLabels.logo}
                        />
                    </Link>
                </div>
                <nav className={style.navWrapper}>
                    <ul className={style.navList}>
                        {navigationData.map(item => {
                            return (
                                <li key={uuidv4()}>
                                    <Link {...item} className={style.navListA} />
                                </li>
                            )
                        })}
                    </ul>
                </nav>
                <Hamburger mobileNavOpen={mobileNavOpen} setMobileNavOpen={setMobileNavOpen} />
                <div className="hidden lg:block">
                    <LanguageSwitcher />
                </div>
                <div
                    className={`${style.mobileNavWrapper} ${
                        mobileNavOpen ? "opacity-100" : "opacity-0 pointer-events-none"
                    }`}
                >
                    <div className={style.mobileNav}>
                        <div className={style.mobileNavContent}>
                            <ul className={style.mobileNavList}>
                                {navigationData.map(item => {
                                    return (
                                        <li key={uuidv4()}>
                                            <Link
                                                {...item}
                                                className={style.mobileNavListA}
                                                onClick={() => setMobileNavOpen(false)}
                                                tabIndex={mobileNavOpen ? "0" : "-1"}
                                            />
                                        </li>
                                    )
                                })}
                            </ul>
                            <div className="self-end pb-2">
                                <LanguageSwitcher />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    logo: PropTypes.object,
    navigationCollection: PropTypes.object,
}

export default Header
