import React, { useState } from "react"
import PropTypes from "prop-types"

import { useGlobalContext } from "../../../context/GlobalContext"
import { defaultStyle as style } from "./HeroCard-tw-styles"
import { gaEventClasses, gaEventLabels } from "../../../constants/gtm.constants"
import Tags from "../Tags/Tags"
import Download from "../../../assets/icons/24x24/Download"
import Stare from "../../../assets/icons/24x24/Stare"

const HeroCard = props => {
    const { recipeName, tags } = props
    const { labels } = useGlobalContext()
    const [isCopyPopupVisible, setIsCopyPopupVisible] = useState(false)
    const copyHandler = () => {
        const tempInput = document.createElement("input")
        tempInput.value = window.location.href
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand("copy")
        document.body.removeChild(tempInput)
        setIsCopyPopupVisible(true)
        setTimeout(() => {
            setIsCopyPopupVisible(false)
        }, 1500)
    }
    const printHandler = () => {
        window.print()
    }
    return (
        <div className={style.wrapper}>
            <h1 className={style.heading}>{recipeName}</h1>
            <div className={style.actions}>
                <div className={style.tags}>
                    <Tags classifications={tags} />
                </div>
                <div className={style.ctaWrapper}>
                    <button
                        type="button"
                        aria-label="Download"
                        className={`${style.cta} ${gaEventClasses.print_page}`}
                        data-action-detail={gaEventLabels.printPage}
                        onClick={printHandler}
                    >
                        <Download styling={style.icon} />
                        <p>{labels?.common?.download}</p>
                    </button>
                    <button
                        type="button"
                        aria-label="Share"
                        className={`${style.cta} ${gaEventClasses.share}`}
                        data-action-detail={gaEventLabels.savePage}
                        onClick={copyHandler}
                    >
                        <Stare styling={style.icon} />
                        <p>{labels?.common?.share}</p>
                        <div className={`${isCopyPopupVisible ? "opacity-100" : "opacity-0"} ${style.linkCopiedPopup}`}>
                            <span className={style.linkCopiedSpan}>{labels?.common?.linkCopied || "Link copied"}</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    )
}

HeroCard.propTypes = {
    recipeName: PropTypes.string,
    tags: PropTypes.array,
    links: PropTypes.array,
}

export default HeroCard
