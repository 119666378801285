import React from "react"
import PropTypes from "prop-types"
import ReCAPTCHA from "react-google-recaptcha"

import { defaultStyle as style } from "./SubscriptionFormSection-tw-styles"

import useSubscriptionForm from "../../../../hooks/useSubscriptionForm"
import { gaEventClasses } from "../../../../constants/gtm.constants"
import parse from "html-react-parser"

const SubscriptionFormSection = props => {
    const { newsletterConfirmation, newsletterConfirmation2, thirdPartyConfirmation, thirdPartyConfirmation2 } = props
    const {
        email,
        emailIsFocused,
        setEmailIsFocused,
        newsletterChecked,
        thirdPartyChecked,
        recaptchaValue,
        errors,
        isSubmitting,
        labels,
        handleInputChange,
        handleNewsletterCheckboxChange,
        handleThirdPartyCheckboxChange,
        handleRecaptchaChange,
        handleSubmit,
        isnewsletterConfirmationExpended,
        newsLettertoggleExpand,
        isthirdPartyConfirmationExpended,
        thirdPartytoggleExpand,
        submitSuccess,
        recaptchaKey,
        language,
    } = useSubscriptionForm()
    return (
        <>
            {!recaptchaKey && <p className="text-white">Recaptcha key is missing!</p>}
            {submitSuccess && recaptchaKey && (
                <div className={style.submitted}>
                    <h1 className={style.submittedTitle}>{labels?.subscriptionForm?.successTitle}</h1>
                    <p className={style.submittedDescription}>{labels?.subscriptionForm?.successDescription}</p>
                </div>
            )}

            {!submitSuccess && recaptchaKey && (
                <form onSubmit={handleSubmit} className={style.wrapper}>
                    <div className={style.emailBlock}>
                        <input
                            className={style.emailInput}
                            type="text"
                            value={email}
                            onChange={handleInputChange}
                            onFocus={() => setEmailIsFocused(true)}
                            onBlur={() => setEmailIsFocused(false)}
                            id="subscriptionEmailInput"
                        />
                        <label
                            className={`${style.emailLabel} ${
                                email.length > 0 || emailIsFocused
                                    ? "-translate-y-4 tag !pointer-events-none"
                                    : "translate-y-0 body-m"
                            }`}
                            htmlFor="subscriptionEmailInput"
                        >
                            {labels?.subscriptionForm?.inputPlaceholder || "Your email"}
                        </label>
                    </div>
                    {errors.email && <div className={style.error}>{errors.email}</div>}
                    <div className={style.consentSection}>
                        <label htmlFor="subscriptionNewsletterCheckbox" className={style.consentGroup}>
                            <input
                                id="subscriptionNewsletterCheckbox"
                                type="checkbox"
                                checked={newsletterChecked}
                                onChange={handleNewsletterCheckboxChange}
                            />
                            <div className="checkbox-icon">&nbsp;</div>
                            <span className={style.consentLabel}>
                                {newsletterConfirmation2 ? <div>
                                    {isnewsletterConfirmationExpended ?
                                        <div>
                                            {parse(newsletterConfirmation)}
                                            {parse(newsletterConfirmation2)}
                                        </div> : <div>
                                            {parse(newsletterConfirmation)}
                                            <button className={style.readMore} onClick={newsLettertoggleExpand}>leer más</button>
                                        </div>}
                                </div> : parse(newsletterConfirmation)}
                            </span>
                        </label>
                        {errors.newsletter && <div className={style.error}>{errors.newsletter}</div>}
                    </div>
                    <div className={style.consentSection}>
                        <label htmlFor="subscriptionThirdPartyCheckbox" className={style.consentGroup}>
                            <input
                                id="subscriptionThirdPartyCheckbox"
                                type="checkbox"
                                checked={thirdPartyChecked}
                                onChange={handleThirdPartyCheckboxChange}
                            />
                            <div className="checkbox-icon">&nbsp;</div>
                            <span className={style.consentLabel}>
                                {thirdPartyConfirmation2 ? <div>
                                    {isthirdPartyConfirmationExpended ?
                                        <div>
                                            {parse(thirdPartyConfirmation)}
                                            {parse(thirdPartyConfirmation2)}
                                        </div> : <div>
                                            {parse(thirdPartyConfirmation)}
                                            <button className={style.readMore} onClick={thirdPartytoggleExpand}>leer más</button>
                                        </div>}
                                </div> : parse(thirdPartyConfirmation)}
                            </span>
                        </label>
                    </div>
                    <div className={style.captchaWrapper}>
                        <ReCAPTCHA
                            sitekey={recaptchaKey}
                            onChange={handleRecaptchaChange}
                            value={recaptchaValue}
                            hl={language}
                        />
                        {errors.recaptcha && <div className={style.error}>{errors.recaptcha}</div>}
                    </div>
                    <button
                        type="submit"
                        aria-label={labels?.subscriptionForm?.ctaPlaceholder || "Subscribe"}
                        className={`${style.cta} ${gaEventClasses.button_click}`}
                        data-action-detail={labels?.subscriptionForm?.ctaPlaceholder || "Subscribe"}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? labels?.subscriptionForm?.pending : labels?.subscriptionForm?.ctaPlaceholder}
                    </button>
                    {errors.submit && <div className={style.error}>{errors.submit}</div>}
                </form>
            )}
        </>
    )
}

SubscriptionFormSection.propTypes = {
    newsletterConfirmation: PropTypes.string,
    newsletterConfirmation2: PropTypes.string,
    thirdPartyConfirmation: PropTypes.string,
    thirdPartyConfirmation2: PropTypes.string,
}

export default SubscriptionFormSection
