import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import * as styles from "./PopupWrapper-tw-styles"
import useScreenSize from "../../../hooks/useScreenSize"

const PopupWrapper = props => {
  const { children, variant, closeOnBodyclick } = props
  const style = styles[variant] || styles.defaultStyle

  const [showModal, setShowModal] = useState(false)
  const [modalDoc, setModalDoc] = useState(null)

  const closeModal = () => {
    document.dispatchEvent(new Event("close_newsletter_modal"))
    setShowModal(false)
  }
  const screen = useScreenSize()

  useEffect(() => {
    let modalTimeout
    const path = location?.pathname || ""

    if (
      (path === "/" || path.startsWith("/es") || path.startsWith("/pt")) &&
          !location.href.includes("newsletter")
      ) {
          modalTimeout = setTimeout(() => {
              setShowModal(true)
          }, 1)
      }

      return () => {
          if (modalTimeout) {
              clearTimeout(modalTimeout)
          }
      }
  }, [])

  const handleCloseOnKeyPress = event => {
    if (event.key === "Escape" || event.key === "Esc") {
      close()
    }
  }
  const onClick = () => {
    if (closeOnBodyclick) {
      close()
    }
  }

  const close = () => {
    closeModal()
  }

  useEffect(() => {
    setModalDoc(document.getElementById("base-modal"))
    document.addEventListener("keydown", handleCloseOnKeyPress)
    return () => {
      document.removeEventListener("keydown", handleCloseOnKeyPress)
    }
  }, [])

  const modal = showModal ? (
      <div className={style?.wrapper} onClick={onClick}>
          <div className={style?.gap}>
              <div className={style?.container}>
                  <div className={style?.content}>
                      <a href={location.pathname.startsWith("/pt") ? "https://passatempo.royalcontigo.com/": "https://www.promo.royalcontigo.com/"} target="_blank" rel="noreferrer">
                          <img
                              src={
                                  screen?.isMobile
                                      ? location.pathname.startsWith("/pt")
                                          ? "https://images.ctfassets.net/gfxilcou9ltt/2zpBjdN743nEEGee4R1dxK/83f37016297eefa183e7d081cfc5ce08/Banner_Mobile_Royal_Easter_PT_375x520.jpg"
                                          : "https://images.ctfassets.net/awi7573i7srw/1EsQwxzlOjMaQmTOYQC8L2/f0744f409acaa270e66d19379a2920f6/Royal_Banner_Mobile.jpg"
                                      : location.pathname.startsWith("/pt")
                                      ? "https://images.ctfassets.net/gfxilcou9ltt/4BpdA0WpgFgoMdFa8bHCHm/876193f6a3b38a0190cda4a03a214970/Banners-Desktop_Royal-Easter-PT_1920x780.jpg"
                                      : "https://images.ctfassets.net/awi7573i7srw/64zxBpzaJ2ru3cneGLqxAY/5c2fbda6246cb56f415849c3cdefc6ad/ROYAL_Banner_Desktop_Mastechef_1920x780px.jpg"
                              }
                          />
                      </a>
                      <button className="modal-close" onClick={close} aria-label="Close" />
                  </div>
              </div>
          </div>
      </div>
  ) : null

  return modalDoc ? ReactDOM.createPortal(modal, modalDoc) : null
}

PopupWrapper.propTypes = {
    closeModal: PropTypes.func,
    children: PropTypes.any,
    variant: PropTypes.string,
    closeOnBodyclick: PropTypes.bool,
}

export default PopupWrapper
