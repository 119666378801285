const CONSTANTS = {
    es: {
        TAGS_CONSTANT: {
            cookingType: 22023,
            occasion: 22030,
            special: 22037,
        },
        DIFFICULTIES_CONSTANT: {
            EASY: 5350,
            MEDIUM: 5351,
            HARD: 5352,
        },
        PORTFOLIO_SORT_CONSTANT: 26302,
        RECIPE_CLASSIFICATION_SORT_CONSTANT: 22154,
    },
    pt: {
        TAGS_CONSTANT: {
            cookingType: 22144,
            occasion: 22145,
            special: 22146,
        },
        DIFFICULTIES_CONSTANT: {
            EASY: 22148,
            MEDIUM: 22149,
            HARD: 22150,
        },
        PORTFOLIO_SORT_CONSTANT: 26309,
        RECIPE_CLASSIFICATION_SORT_CONSTANT: 22162,
    },
}

const mondelezAPI = {
    "ES-ES": {
        brandID: 26027,
        siteID: "royales2023",
        websiteID: 154,
        apiLocale: "eses",
        languageID: 2,
        sectorID: "20234",
        classificationList: [
            {
                type: "cookingType",
                id: CONSTANTS.es.TAGS_CONSTANT.cookingType,
            },
            {
                type: "occasion",
                id: CONSTANTS.es.TAGS_CONSTANT.occasion,
            },
            {
                type: "special",
                id: CONSTANTS.es.TAGS_CONSTANT.special,
            },
        ],
        timeSpan: [
            {
                type: "prepTime",
                value: 43,
            },
            {
                type: "totalTime",
                value: 41,
            },
        ],
    },
    "PT-PT": {
        brandID: 26028,
        siteID: "royalpt2023",
        websiteID: 153,
        apiLocale: "ptpt",
        languageID: 2,
        sectorID: "20234",
        classificationList: [
            {
                type: "cookingType",
                id: CONSTANTS.pt.TAGS_CONSTANT.cookingType,
            },
            {
                type: "occasion",
                id: CONSTANTS.pt.TAGS_CONSTANT.occasion,
            },
            {
                type: "special",
                id: CONSTANTS.pt.TAGS_CONSTANT.special,
            },
        ],
        timeSpan: [
            {
                type: "prepTime",
                value: 275,
            },
            {
                type: "totalTime",
                value: 271,
            },
        ],
    }
}

module.exports = {
    mondelezAPI,
    CONSTANTS
}
