export const defaultStyle = {
    wrapper: "relative w-full mt-[28px] max-w-[523px] lg:max-w-none lg:grow lg:mt-0",
    submitted: "relative w-full mt-[28px] text-white text-center lg:grow lg:mt-0 max-w-[600px]",
    emailBlock: "relative flex items-center",
    emailInput: "w-full pt-6 pr-5 pb-2 pl-5 border border-black rounded-[15px] body-m font-bold focus:outline-none",
    emailLabel: "absolute left-5 transition-transform transform text-black",
    consentSection: "text-white mt-[20px] w-full text-[14px]",
    consentGroup: "flex items-start [&_a]:cursor-pointer [&_a]:underline consentCheckbox",
    consentLabel: "-mt-1 ml-2 select-none",
    captchaWrapper: "w-full my-[35px] min-h-[78px] lg:max-w-[453px]",
    cta: "button-primary w-full max-w-[400px] mx-auto lg:mx-0 lg:max-w-[240px]",
    error: "body-s text-black font-semibold px-2 mt-1",
    submittedTitle: "body-l font-bold",
    submittedDescription: "body-l",
    readMore: "underline text-[#34d0eb]"
}
