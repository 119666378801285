import React from "react"
import PropTypes from "prop-types"

import { defaultStyle as style } from "./RecipeDetailPageHero-tw-styles"

import ResponsiveImage from "../ResponsiveImage/ResponsiveImage"
import WaveDivider, { COLOR } from "../../WaveDivider/WaveDivider"
import HeroCard from "../HeroCard/HeroCard"
import { labels as recipeLabels } from "../../../constants/recipe.constants"
import { convertToSecureUrl } from "../../../helpers/utils"
import { useGlobalContext } from "../../../context/GlobalContext"

const RecipeDetailPageHero = props => {
    const { id, recipe } = props
    const { language } = useGlobalContext()
    const { PhotoURLAdditional, RecipeName, Classifications } = recipe || {}

    const getImageUrl = list => {
        const filteredList = list?.filter(item => item?.PhotoTypeID === recipeLabels[language].BANNER_DESKTOP_PHOTO_TYPE_ID)
        return Array.isArray(filteredList) && filteredList?.length > 0
            ? convertToSecureUrl(filteredList[0].PhotoLink)
            : "https://images.ctfassets.net/awi7573i7srw/58Umhy1mWLPrkjQumfn0rC/c7f4e62707671eb44ae2c9acb973f0b7/Hero_section___divider_1__copy.png"
    }

    return (
        <>
            <section id={id} className={style.wrapper}>
                <div className={style.bgWrapper}>
                    <ResponsiveImage
                        className={style.image}
                        contentfulImagesComponent={{
                            imageDesktop: {
                                url: getImageUrl(PhotoURLAdditional),
                                width: 1920,
                                height: 780,
                            },
                        }}
                    />
                </div>
                <div className={style.waveMobile}>
                    <WaveDivider color={COLOR.BEIGE} />
                </div>
                <div className={style.heroCardWrapper}>
                    <div className={style.heroCardContainer}>
                        <HeroCard recipeName={RecipeName} tags={Classifications} />
                    </div>
                </div>
            </section>
            <div className={style.waveDesktop}>
                <WaveDivider color={COLOR.BEIGE} />
            </div>
        </>
    )
}

RecipeDetailPageHero.propTypes = {
    id: PropTypes.string,
    recipe: PropTypes.object,
}

export default RecipeDetailPageHero
