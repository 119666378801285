const algoliaAPI = (prod) => {
    const env = prod ? "production" : "development";

    const indexNames = {
        development: {
            es: {
                recipesIndex: "royalcontigo_com_es_recipe_dev",
                similarRecipesIndex: "royalcontigo_com_es_similarrecipes_dev",
            },
            pt: {
                recipesIndex: "royalcontigo_com_pt_recipe_dev",
                similarRecipesIndex: "royalcontigo_com_pt_similarrecipes_dev",
            }
        },
        production: {
            es: {
                recipesIndex: "royalcontigo_com_es_recipe",
                similarRecipesIndex: "royalcontigo_com_es_similarrecipes",
            },
            pt: {
                recipesIndex: "royalcontigo_com_pt_recipe",
                similarRecipesIndex: "royalcontigo_com_pt_similarrecipes",
            }
        }
    }

    const sharedConfig = [
        {
            pageLanguage: "es",
            searchKey: "4a0efca386695abd97123d98d6703d1b",
        },
        {
            pageLanguage: "pt",
            searchKey: "d8c65301be394a4d1fe7693e6acdb753",
        }
    ]

    return sharedConfig.map(config => ({
        ...config,
        recipesIndex: indexNames[env]?.[config.pageLanguage]?.recipesIndex || "",
        similarRecipesIndex: indexNames[env]?.[config.pageLanguage]?.similarRecipesIndex || "",
    }))
}



const subscriptionAPI = (env) => {
    const appSiteKeys = {
        development: {
            es: "6Ldsb3opAAAAAMIW4RVF1afOM_gU5As5FmYh0ibh",
            pt: "6LcXr50pAAAAAIqfFk6uzOKvtdb5L86IJzZ8-xRB",
        },
        staging: {
            es: "6LdsOJEpAAAAAJUi0bT5wwbFTlJ5e6gCHhwu0tgt",
            pt: "6LdEvZwpAAAAAAFS4H2HHcY15WCo9nq7CA8lVmUU",
        },
        production: {
            es: "6LddRpwpAAAAABcDU0FHpFmovcbzbLYyFJPz6eqQ",
            pt: "6LeRhZwpAAAAAMVIXxYOAWWFOri39ZDjcf8nBNWv",
        }
    }
    const sharedConfig = [
        {
            pageLanguage: "es",
            requestDomain: "royalcontigo-com",
            requestCountry: "es",
            requestLanguage: "es",
            requestCampaign: "ROES240201 Royal ES Newsletter Registration",
            requestTransaction: "ROES240201 Royal ES Newsletter Registration (IN)",
        },
        {
            pageLanguage: "pt",
            requestDomain: "www.royalcontigo.com",
            requestCountry: "pt",
            requestLanguage: "pt",
            requestCampaign: "ROPT240101 Royal PT Newsletter Registration Form",
            requestTransaction: "ROPT240101 Royal PT Newsletter Registration (IN)",
        }
    ]
    return sharedConfig.map(config => ({
        ...config,
        appSiteKey: appSiteKeys[env]?.[config.pageLanguage] || "",
    }))
}
module.exports = { algoliaAPI, subscriptionAPI }
